import React, { useState } from 'react';

import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";

import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box';
import PublishIcon from '@material-ui/icons/Publish';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

import { apiErrorHandler, extractExtension, sizeCalculator } from '../../../utils/helper';
import { ChatService } from '../../../utils/chat';
import { FILE_SIZE, ALLOWED_FILES, FILE_SIZE_8MB } from '../../../utils/messages';

import sendPic from '../../../assets/image/send.png';
import paperclip from '../../../assets/image/paperclip.png';
import documentIcon from '../../../assets/image/documentIcon.svg';

import './chat-new.scss';


const InputType = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");
  const { hitDownloadChat, sendAgoraMessage, sendAgoraFile } = props;

  const [uploading, setUploading] = useState(0);

  const [fileDetails, setFileDetails] = useState({
    fileName: null,
    size: null,
    extension: null,
    tag: null,
    file: null
  });

  async function uploadAttachment() {

    const formData = new FormData();
    formData.append('file', fileDetails.file);
    formData.append('random_id', String(Date.now()));

    const apiResponse = await ChatService.chatUpload(setUploading, formData, session.token);
    if (apiResponse.status === 200) {
      uploadSuccess(apiResponse.data.data.file_url);
    } else {
      setUploading(0);
      setFileDetails({
        fileName: null,
        size: null,
        extension: null,
        tag: null,
        file: null
      });
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function uploadSuccess(fileUrl) {
    setUploading(0);

    const fileMessage = {
      fileName: fileDetails.fileName,
      size: fileDetails.size,
      extension: fileDetails.extension,
      tag: fileDetails.tag,
      file: fileUrl
    }
    await sendAgoraFile(fileMessage);
    hitDownloadChat();
    setFileDetails({
      fileName: null,
      size: null,
      extension: null,
      tag: null,
      file: null
    });
  }

  const onSelectFile = (element) => {
    // console.log('got hit file select');
    if (element.target.files && element.target.files.length > 0) {
      if (element.target.files[0].size < 8388608) {

        const holdFile = element.target.files[0];
        setFileDetails({
          fileName: holdFile.name,
          size: sizeCalculator(holdFile.size),
          tag: holdFile.type,
          extension: extractExtension(holdFile.name),
          file: element.target.files[0]
        });

        const inputObject = document.getElementById('fileToUpload');
        inputObject.value = null;

      } else {
        enqueueSnackbar(FILE_SIZE_8MB, { variant: "error" });
        const inputObject = document.getElementById('fileToUpload');
        inputObject.value = null;
      }
    }
  };

  function removeSelection() {
    setFileDetails({
      fileName: null,
      size: null,
      extension: null,
      tag: null,
      file: null
    });
  }

  function submitionDirect(event) {
    if (event.keyCode == 13) {

      const messageToSend = document.getElementById('msg_box').value;
      const spaceFilter = messageToSend.trim();
      if (spaceFilter !== '') {
        sendAgoraMessage();
      }
    }
  }

  // console.log('uploading state', uploading);

  return (

    <div className="chat-writing-section">
      <textarea
        placeholder="Type your message..."
        className="wrbox"
        maxLength="500"
        id="msg_box"
        onKeyDown={submitionDirect}
      ></textarea>
      <div className="chat-controls ">

        {fileDetails.fileName !== null ?
          <div className="file-details">
            <div className="file-image">
              <img src={documentIcon} alt="attach" />
            </div>
            <div className="file-name">Name - {fileDetails.fileName}</div>
            <div className="file-size">Size - {fileDetails.size}</div>

            <div className="uploading-action">
              {uploading !== 0 ?
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={uploading} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {uploading}
                  </Box>
                </Box>
                :
                <>
                  <IconButton onClick={uploadAttachment} > <PublishIcon /></IconButton>
                  <IconButton onClick={removeSelection} > <CancelIcon /> </IconButton>
                </>
              }
            </div>
          </div>
          : null}

        <div className="chat-attach m-0">
          {uploading === 0 ?
            <label htmlFor="fileToUpload">
              <img src={paperclip} alt="attach" />
            </label>
            : null}
          <input
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            className="d-none"
            onChange={onSelectFile}
            accept={ALLOWED_FILES}
          />
        </div>
        <div className="chat-hit ml-1" onClick={sendAgoraMessage}>
          <img src={sendPic} alt="send-message" />
        </div>
      </div>
    </div>
  );
}

export default InputType;