import API from "./API"
import { API_WRONG, ORDER_CREATED } from "./messages";
import timeValidator from './tapOutChecker';

const getOrders = async (type, size, pageNumber, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`get_incomplete_order/?type=${type}&size=${size}&page_no=${pageNumber}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};


const assignAnalyst = async (updateData, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.put(`update_ticket_analyst/`, updateData ,config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const getAnalystOrders = async (userId, completeState, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`analyst-orders/?user_id=${userId}&completed=${completeState}` ,config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const getTicketDetail = async (ticketId, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`get-ticket-details/?ticket_id=${ticketId}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const updateTicketState = async (updatePayload, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.put(`analyst-orders/`, updatePayload, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const uploadTicketReport = async (ticketReport, setUploadStatus, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadStatus(percentCompleted);
        }
      };
      let response = await API.post(`report_upload/`, ticketReport, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const getTicketReports = async (ticket_id, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`report_upload/?ticket_id=${ticket_id}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const updateReport = async (updateJson, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.put(`report_upload/`, updateJson, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const deleteReport = async (reportId, fileName, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.delete(`report_upload/?report_upload_id=${reportId}&report_name=${fileName}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

export const OrderService = {
  getOrders,
  assignAnalyst,
  getAnalystOrders,
  getTicketDetail,
  updateTicketState,
  uploadTicketReport,
  getTicketReports,
  updateReport,
  deleteReport
}
