import React from 'react';

import { useSession } from 'react-use-session';

import documentIcon from '../../../assets/image/documentIcon.svg';

const ChatCard = (props) => {

  const { chatDetails } = props;
  const { session } = useSession("cyber-security");

  return (
    <div className={`${chatDetails.sender_id === session.user_id ? 'outgoing-message-wrapper' : 'incoming-message-wrapper'} `}>
      {chatDetails.is_file === false ?
        <div className="incoming-message">
          <div className="actual-message">{chatDetails.message}</div>
          <div className='message-time'>{chatDetails.created_at}</div>
        </div>
        :
        <div className="chat-file">
          <div className="chat-file-image">
            <a href={chatDetails.message} target="_blank" ><img src={documentIcon} alt="file-image" /></a>
          </div>
          <div className='chat-file-date'>{chatDetails.file_details.size}</div>
          <div className='chat-file-date'>{chatDetails.file_details.fileName}</div>
          <div className='chat-file-date'>{chatDetails.created_at}</div>
        </div>
      }
    </div>
  )

}

export default ChatCard;