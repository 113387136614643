import moment from 'moment';

const timeValidator = async () => {

  const prevTime = moment(parseInt(localStorage.getItem('tapOutTime')));
  const currTime = moment(Date.now());

  const timeDiff = moment.duration(currTime.diff(prevTime)).asMinutes();
  
  if (timeDiff > 1440) {
    localStorage.clear();
    window.location.replace(`https://${window.location.host}/`);
  }else{
    localStorage.setItem('tapOutTime', Date.now());
  }


}
export default timeValidator;
